.conversejs {
    #controlbox {
        #chatrooms {
            padding: 0;

            .add-chatroom {
                input[type=button],
                input[type=submit],
                input[type=text] {
                    width: 100%;
                }
                margin: 0;
                padding: 0;
            }
        }

        .open-rooms-toggle, .open-rooms-toggle .fa {
            color: var(--groupchats-header-color) !important;
            &:hover {
                color: var(--chatroom-head-bg-color-dark) !important;
            }
        }

        .open-rooms-toggle {
            white-space: nowrap;
        }
    }
}
