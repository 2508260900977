@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "shared/styles/_variables.scss";

@import "./controlbox.scss";
@import "./muc.scss";

converse-muc-disconnected,
converse-muc-destroyed {
    padding: 2em;
    width: 100%;
    height: 100%;
}

.conversejs.converse-embedded,
.conversejs {
    .badge--muc {
        background-color: var(--groupchats-header-color);
    }

    .add-chatroom {
        input[type="submit"],
        input[type="button"] {
            margin: 0.3em 0;
        }
    }
}


/* ******************* Overlay  styles *************************** */

.conversejs {
    converse-chats {
        &.converse-overlayed {
            .chatbox {
                &.chatroom {
                    min-width: var(--chatroom-width) !important;
                    width: var(--chatroom-width);
                    .box-flyout {
                        min-width: var(--chatroom-width) !important;
                        width: var(--chatroom-width);
                    }
                    .chatbox-title__text {
                        @include make-col(10);
                    }
                    .chatbox-title__buttons {
                        @include make-col(2);
                    }

                    .chat-head__desc {
                        font-size: 80%;
                        margin-bottom: 1em;
                    }
                    .chatroom-body {
                        .occupants {
                            .occupants-heading {
                                padding: 0;
                            }
                            .occupant-list {
                                border-bottom: none;
                            }
                            ul {
                                .occupant {
                                    .occupant-nick-badge {
                                        .occupant-badges {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .chat-area {
                            min-width: var(--overlayed-chat-width);
                        }
                    }
                }
            }
        }

        &.converse-embedded,
        &.converse-fullscreen,
        &.converse-mobile {

            .chatroom {
                .box-flyout {
                    width: 100%;

                    .chatroom-body {
                        .chat-area {
                            &.full {
                                .new-msgs-indicator {
                                    max-width: 100%;
                                }
                            }
                        }
                        .occupants {
                            padding: var(--occupants-padding);
                            .occupants-heading {
                                font-size: var(--font-size-large);
                            }
                            ul {
                                &.occupant-list {
                                    li {
                                        font-size: var(--font-size-small);
                                    }
                                }
                            }
                        }
                    }
                }
                .room-invite {
                    span {
                        .invited-contact {
                            margin: 0 0 0.5em -1px;
                        }
                    }
                }
            }
        }

        &.converse-embedded {
            .chatroom {
                margin: 0;
                width: 100%;
                .box-flyout {
                    .occupants-heading {
                        font-size: 120%;
                    }
                    .chat-content {
                        .chat-message {
                            margin: 0.5em;
                            font-size: 120%;
                        }
                    }
                    .sendXMPPMessage {
                        .chat-textarea {
                            padding: 0.5em;
                            font-size: 110%;
                        }
                    }
                    .chatroom-body {
                        height: 100%;
                        .muc-form-container {
                            height: 100%;
                            position: relative;
                        }
                    }
                    .occupants {
                        .occupant-list {
                            padding-left: 0.3em;
                        }
                    }
                }
            }
        }
    }
}
