.conversejs {
    converse-muc.chatroom {
        converse-muc-bottom-panel.bottom-panel {
            display: contents;
            height: 3em;
            padding: 0.5em;
            text-align: center;
            font-size: var(--font-size-small);
            background-color: var(--chatroom-head-bg-color);
            color: white;

            &.muc-bottom-panel--muted {
                height: 4em;
                width: 100%;
            }

            &.muc-bottom-panel--nickname {
                padding: 0;
                height: 16em;

                .muc-form-container {
                    .chatroom-form {
                        padding-top: 2em;
                        padding-bottom: 0;
                    }
                }
            }

            .sendXMPPMessage {
                .suggestion-box__results--above {
                    bottom: 4.5em;
                }
                .chat-textarea, input {
                    &:active, &:focus{
                        outline-color: var(--chatroom-head-bg-color) !important;
                    }
                    &.correcting {
                        background-color: var(--chatroom-correcting-color);
                    }
                }
                .chat-textarea {
                    width: 100%;
                    border: none;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}
